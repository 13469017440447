<template>
  <div>
    <template v-if="dropzone">
      <div class="js-upload uk-placeholder uk-text-center">
        <span data-uk-icon="cloud-upload"></span>&nbsp;
        <span class="uk-text-middle uk-text-small">Bild(er) /Datei(en) hier rein ziehen oder </span>
        <div data-uk-form-custom>
          <input type="file" :multiple="multiple">
          <span class="uk-link uk-text-small">klicken zum hochladen</span>
        </div>
      </div>
      <progress id="js-progressbar" class="uk-progress" value="0" max="100" hidden></progress>
    </template>
    <div class="js-upload" uk-form-custom v-else>
      <input type="file" :multiple="multiple" />
      <button class="uk-button uk-button-default" type="button" tabindex="-1">Hochladen</button>
    </div>
    <div class="uk-grid" v-if="fileError">
      <div class="uk-width-1-1">
        <div class="uk-alert uk-alert-danger">Keine Bild Datei!</div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    multiple: {
      type: Boolean,
      required: false,
      default: false,
    },
    type: {
      type: String,
    },
    root: {
      type: String,
    },
    path: {
      type: String,
    },
    url: {
      type: String,
      required: true,
    },
    dropzone: {
      type: Boolean,
      required: false,
      default: false,
    }
  },

  data() {
    return {
      images: [],
      uploadComplete: false,
      uploaded: [],
      fileError: false,
      imageCount: 0,
    }
  },

  mounted() {
    let $this = this
    //const bar = document.getElementById('js-progressbar');
    this.$uikit.upload('.js-upload', {
      url: this.url,
      multiple: this.multiple,
      params: {
        type: this.type,
      },

      beforeSend: (e) => (e.headers = { 'Authorization': window.localStorage.getItem('localforage/ansolution_report/authtoken').replaceAll('"', '') }),
      beforeAll: (e) => {
        e.params.path = this.path
        e.params.root = this.root
      },
      load: () => { },
      error: () => { },
      complete: (e) => {
        let json = JSON.parse(e.response)
        $this.uploaded.push(json)
        $this.imageCount++
        $this.$emit('uploaded', { data: $this.images[$this.imageCount - 1], path: json.path, id: json.id, url: json.url, ref: json.ref })
      },
      loadStart: (e) => {
        $this.$emit('start', e)
      },
      progress: (e) => {
        $this.$emit('progress', e)
      },
      loadEnd: (e) => {
        $this.$emit('end', e)
      },
      completeAll: () => {
        $this.$emit('completeAll')
        $this.uploadComplete = true
      },
    })
  },
}
</script>
