<template>
  <div>
    <div class="uk-section uk-section-xsmall uk-padding-remove-top">
      <div data-uk-sticky="offset:52;media: @m" class="uk-background-default breadcrumb" style="padding: 15px 35px; border-bottom: 1px solid rgb(229, 229, 229)">
        <div class="uk-flex uk-flex-between uk-flex-middle">
          <div class="uk-flex uk-flex-middle">
            <h1 class="uk-h5 uk-margin-remove uk-flex uk-flex-middle" style="color: rgb(51, 51, 51)">
              <svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20" fill="currentColor" class="uk-margin-small-right">
                <path fill-rule="evenodd" d="M4 3a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V5a2 2 0 00-2-2H4zm12 12H4l4-8 3 6 2-4 3 6z" clip-rule="evenodd" />
              </svg>
              Medien
            </h1>
          </div>
        </div>
      </div>
    </div>
    <div class="uk-section uk-section-small">
      <div class="uk-container uk-container-expand">
        <div class="uk-grid uk-grid-small" data-uk-grid>
          <div class="uk-width-1-1">
            <div class="uk-card uk-card-default uk-card-small">
              <div class="uk-card-body">
                <finder ref="finder" mode="write"></finder>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Finder from '@/components/finder/Index'
export default {
  components: {
    Finder,
  },
}
</script>
